import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Navbar from "react-bootstrap/Navbar";
import * as Scroll from "react-scroll";
import Slider from "react-slick";

import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

class Projects extends Component {
  render() {
    return (
      <CardDeck className="justify-content-center">
        <a
          href="https://towardsdatascience.com/image-to-text-generation-for-new-yorker-cartoons-f5f145eb6208"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card id="new-yorker-cartoons">
            <Card.Img
              variant="top"
              src="/images/new_yorker_cartoons.jpg"
            />
            <div className="overlay">
              <div className="overlayContent">
                <h3>
                  Image-to-Text Generation for New Yorker Cartoons&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </h3>
                <hr />
                <p>
                  Fine-tuning an image-to-text model to generate candidate captions for New Yorker Cartoons
                </p>
              </div>
            </div>
          </Card>
        </a>      
        <a
          href="https://github.com/eugenet12/pytorch-rbm-autoencoder"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card id="pytorch-rbm-autoencoder">
            <Card.Img
              variant="top"
              src="/images/rbm_autoencoder_screenshot.png"
            />
            <div className="overlay">
              <div className="overlayContent">
                <h3>
                  Improving Autoencoder Performance with Pretrained RBMs&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </h3>
                <hr />
                <p>
                  An open-source pytorch implementation of improving autoencorder performance with pretrained RBMs based on Hinton and
                  Salakhutdinov's paper "Reducing the Dimensionality of
                  Data with Neural Networks" (2006)
                </p>
              </div>
            </div>
          </Card>
        </a>
        <a
          href="https://coviddashboard.eugenectang.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card id="covid-19-dashboard">
            <Card.Img
              variant="top"
              src="/images/covid_dashboard_screenshot.png"
            />
            <div className="overlay">
              <div className="overlayContent">
                <h3>
                  Covid-19 Dashboard&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </h3>
                <hr />
                <p>
                  Visualizations for Covid-19 case counts, treatment progress,
                  and vaccine progress
                </p>
              </div>
            </div>
          </Card>
        </a>

      </CardDeck>
    );
  }
}

class TextSlider extends Component {
  renderArticle(article) {
    let link = article["link"];
    let image = article["image"];
    let title = article["title"];
    let subtitle = article["subtitle"];
    let text = article["text"];
    return (
      <div key={link}>
        <Card>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <Card.Img variant="top" src={image} />
          </a>
          <Card.Body className="writingBody">
            <Card.Title>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {title}
              </a>
            </Card.Title>
            <span className="writingSubheader">{subtitle}</span>
            <hr />
            <Card.Text>{text}</Card.Text>
          </Card.Body>
        </Card>
      </div>
    );
  }

  render() {
    let slidesToShow = 3;
    if (this.props.width < 768) {
      slidesToShow = 1;
    } else if (this.props.width < 1100) {
      slidesToShow = 2;
    }

    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToShow,
    };

    let articles = [
      // {
      //   link: "https://towardsdatascience.com/image-to-text-generation-for-new-yorker-cartoons-f5f145eb6208",
      //   image: "/images/new_yorker_cartoons.jpg",
      //   title: "Image-to-Text Generation for New Yorker Cartoons",
      //   subtitle: "March 31, 2021 | Towards Data Science",
      //   text: "Fine-tuning an image-to-text model to generate cartoon captions"
      // },
      {
        link: "https://towardsdatascience.com/improving-autoencoder-performance-with-pretrained-rbms-e2e13113c782",
        image: "/images/rbm_autoencoder_thumbnail.png",
        title: "Improving Autoencoder Performance with Pretrained RBMs",
        subtitle: "December 17, 2020 | Towards Data Science",
        text: "A pytorch tutorial on how to improve autoencoder performance through pretraining RBMs"
      },
      {
        link:
          "https://medium.com/@eugene.c.tang/one-shot-learning-character-recognition-explained-54186327622d?source=friends_link&sk=6d2106c3ca238a72c5248b3f33762141",
        image: "/images/one_shot_learning_thumbnail_v2.png",
        title: "One-shot Learning Explained",
        subtitle: "February 28, 2017 | Medium",
        text:
          "Describing a novel one-shot learning algorithm for character recognition",
      },
      {
        link:
          "https://medium.com/@eugene.c.tang/things-i-learned-from-my-first-kaggle-competition-362409a9f5b0?source=friends_link&sk=17e49797b631c13586350c308b4e5603",
        image: "/images/first_kaggle_thumbnail.png",
        title: "My First Kaggle Competition",
        subtitle: "July 7, 2020 | Medium",
        text:
          "What I learned from my first Kaggle competition",
      },
      {
        link:
          "https://medium.com/@eugene.c.tang/the-science-behind-touchscreens-272c8e6038d0?source=friends_link&sk=e651b1a0d16378c5415f91bfbe9028a6",
        image: "/images/touchscreen_thumbnail.png",
        title: "The Science behind Touchscreens",
        subtitle: "Spring 2015 | Innovation Magazine",
        text: "A brief look at how touchscreens work",
      },
      {
        link: "/documents/innovation_privacy.pdf",
        image: "/images/privacy_thumbnail.png",
        title: "Privacy, Please: Expanding Identities in the Digital World",
        subtitle: "Spring 2013 | Innovation Magazine",
        text:
          "Exploring recent data privacy research and how our data is not as anonymous as we would think",
      },
      {
        link: "/documents/innovation_distractionremoval.pdf",
        image: "/images/distraction_removal_thumbnail.png",
        title:
          "How Researchers are Automatically Removing Distractions from Photos",
        subtitle: "Summer 2015 | Innovation Magazine",
        text: "Describing a novel algorithm to remove distractions from photos",
      },
      {
        link: "/documents/innovation_fiscalnote.pdf",
        image: "/images/fiscalnote_thumbnail.png",
        title: "Changing the Political Landscape with Machine Learning",
        subtitle: "Fall 2013 | Innovation Magazine",
        text:
          "A piece on FiscalNote, a startup using machine learning to help users navigate politics",
      },
    ];
    return (
      <Slider {...settings} id="writing-slider">
        {articles.map((article) => this.renderArticle(article))}
      </Slider>
    );
  }
}

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = { atBottomPage: false, width: 0, height: 0 };
    this.handleScroll = this.handleScroll.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    window.removeEventListener("scroll", this.handleScroll);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleScroll() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      this.setState({ atBottomPage: true });
      //show loading spinner and make fetch request to api
    } else {
      this.setState({ atBottomPage: false });
    }
  }

  render() {
    let headerSpacer = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
    let technicalWritingTitle = "Technical Writing";
    if (this.state.width < 400) {
      technicalWritingTitle = "Writing";
    }
    return (
      <div>
        <Navbar fixed="top" className="justify-content-center" id="header">
          <Scroll.Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            duration={500}
          >
            About
          </Scroll.Link>
          {headerSpacer}
          <Scroll.Link
            activeClass="active"
            to="projects"
            spy={true}
            smooth={true}
            duration={500}
          >
            Projects
          </Scroll.Link>
          {headerSpacer}
          <Scroll.Link
            activeClass={"active"}
            to="technical-writing"
            spy={true}
            smooth={true}
            duration={500}
            className={this.state.atBottomPage ? "inactive" : ""}
          >
            {technicalWritingTitle}
          </Scroll.Link>
          {headerSpacer}
          <Scroll.Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            duration={500}
            className={this.state.atBottomPage ? "active" : ""}
          >
            Contact
          </Scroll.Link>
        </Navbar>

        <Scroll.Element name="about" className="scrollElement" id="about">
          <div
            id="about-container"
            className="justify-content-center text-center"
          >
            <div id="profile-image">
              <div id="profile-image-in1">
                <div id="profile-image-in2"></div>
              </div>
            </div>
            <br />
            <span id="about-container-header">Eugene Tang</span>
            <br />
            <span id="about-container-description">
              Research Engineer
            </span>
          </div>
        </Scroll.Element>
        <Scroll.Element
          name="projects"
          className="scrollElement bodyElement"
          id="projects"
        >
          <div
            id="projects-container"
            className="justify-content-center text-center"
          >
            <div className="containerHeader">Projects</div>
            <div className="containerSubheader"></div>
            <Projects />
          </div>
        </Scroll.Element>
        <Scroll.Element
          name="technical-writing"
          className="scrollElement bodyElement"
          id="technical-writing"
        >
          <div
            id="technical-writing-container"
            className="justify-content-center text-center"
          >
            <div className="containerHeader">Technical Writing</div>
            <div className="containerSubheader"></div>
            <TextSlider width={this.state.width} height={this.state.height} />
          </div>
        </Scroll.Element>
        <Scroll.Element
          name="contact"
          className="scrollElement bodyElement"
          id="contact"
        >
          <div
            id="contact-container"
            className="justify-content-center text-center"
          >
            <div className="containerHeader">Contact</div>
            <div id="contact-links">
              <a href="mailto:eugene.c.tang@gmail.com" id="email-link">
                <FontAwesomeIcon icon={faEnvelope} />
                &nbsp;&nbsp;
                <span className="linkText">eugene.c.tang [at] gmail.com</span>
                &nbsp;&nbsp;&nbsp;
              </a>
              <br />
              <a
                href="https://www.linkedin.com/in/eutang/"
                _target="blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
                &nbsp;&nbsp;
                <span className="linkText">
                  https://www.linkedin.com/in/eutang/
                </span>
              </a>
            </div>
            <div id="attribution-container">
              <a
                href="https://www.freepik.com/vectors/background"
                id="attribution-link"
              >
                Background vector from Freepik
              </a>
              <br />
              &#169; Eugene Tang
            </div>
          </div>
        </Scroll.Element>
      </div>
    );
  }
}

class App extends Component {
  render() {
    return <MainPage />;
  }
}

export default App;
